import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeModule} from './home/home.module';
import {SharedModule} from '../shared/shared.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ContactModule } from './contact/contact.module';

@NgModule({
  imports: [
    CommonModule,
    HomeModule,
    SharedModule,
    PortfolioModule,
    ContactModule
  ],
  declarations: []
})
export class MainModule { }
